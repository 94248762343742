<template>
  <div>
    <div class="geo-search">
      <div class="geo-title">{{ $t("Geofence.GeofencProperties") }}</div>
      <b-button variant="primary" class="btn-icon" @click="redirectBackToList">
        <feather-icon icon="ArrowLeftIcon" />
        <span class="d-none d-sm-inline ml-1">{{ $t("Geofence.Back") }}</span>
      </b-button>
    </div>

    <div class="geo-outer">
      <b-form>
        <validation-observer ref="sensorsRef" tag="form" #default="{ invalid }">
          <div ref="dynamicHeightDiv" style="overflow-y: auto; padding: 15px">
            <div class="input-outer">
              <b-form-group :label="$t('Geofence.Name') + '*'">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  :rules="{
                    required: true,
                    min: constants.MIN_GEOFENCE_NAME,
                    max: constants.MAX_GEOFENCE_NAME
                  }"
                >
                  <b-form-input
                    :disabled="!isEditable"
                    v-model="geoFence.name"
                    :placeholder="$t('Geofence.EnterGeofenceName')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="textarea-outer">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >{{ $t("Geofence.Description") }}
              </label>
              <validation-provider
                #default="{ errors }"
                name="description"
                vid="description"
                :rules="{
                  max: 400
                }"
              >
                <b-form-textarea
                  :disabled="!isEditable"
                  v-model="geoFence.description"
                  rows="3"
                />
                <small class="text-danger">{{
                  errors[0] ? handleError(errors[0]) : ""
                }}</small>
              </validation-provider>
            </div>
            <div class="textarea-outer color-pick-out">
              <label class="bv-no-focus-ring col-form-label pt-0">{{
                $t("Geofence.Color")
              }}</label>
              <ColorInput
                @input="changeColor"
                v-model="geoFence.color"
                :isEditable="isEditable"
              />
            </div>
            <div class="input-outer">
              <b-form-group :label="$t('Geofence.Label')">
                <TagMultiSelect
                  :class="!isEditable ? 'disabled' : ''"
                  v-model="geoFence.labels"
                />
              </b-form-group>
            </div>
            <div class="input-outer">
              <b-form-group>
                <b-form-checkbox
                  :disabled="!isEditable"
                  v-model="geoFence.allow_child"
                  class="form-control-merge cursor-pointer"
                >
                  {{ $t("geofence.AllowChild") }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="input-outer">
              <b-form-group>
                <b-form-checkbox
                  :disabled="!isEditable"
                  v-model="geoFence.allow_address_resolution"
                  class="form-control-merge cursor-pointer"
                >
                  {{ $t("geofence.allowAddressResolution") }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="input-outer">
              <label class="bv-no-focus-ring col-form-label pt-0">
                {{ $t("Geofence.Visibility") }}
                <feather-icon
                  icon="InfoIcon"
                  :title="$t('geoFence.VisibilityInfo')"
                  class="text-primary ml-0-8"
                  v-b-tooltip.hover.v-primary
              /></label>
              <vue-slider
                :disabled="!isEditable"
                @change="visibilityChange"
                v-model="geoFence.visibility"
                :max="19"
                class="mb-2"
              />
            </div>
            <div>
              <div class="area-input">
                <span class="in-label mr-1">{{ $t("Geofence.Area") }}:</span>
                <span class="in-text"
                  >{{
                    geoFence.area ? (geoFence.area / 10000).toFixed(2) : 0
                  }}
                  ha</span
                >
              </div>
              <div class="area-input">
                <span class="in-label mr-1"
                  >{{ $t("Geofence.Perimeter") }}:</span
                >
                <span class="in-text"
                  >{{ formattedPerimeter(geoFence.perimeter) }}
                </span>
              </div>
            </div>
          </div>
          <div class="pagination-outer-1">
            <b-button
              variant="outline-primary"
              @click="cancel"
              class="sub-icon"
            >
              {{ $t("Geofence.Cancel") }}</b-button
            >
            <b-button
              variant="outline-primary"
              :disabled="!isEditable"
              class="sub-icon"
              @click="clear"
            >
              {{ $t("Geofence.Clear") }}</b-button
            >
            <b-button
              variant="primary"
              :disabled="
                invalid || !validateCoordinate() || showLoading || !isEditable
              "
              @click="saveGeoFences"
              class="sub-icon"
            >
              {{ $t("Geofence.Save") }}</b-button
            >
          </div>
        </validation-observer></b-form
      >
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormCheckbox,
  BAvatar,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormInput,
  BForm,
  VBTooltip,
  BFormTextarea
} from "bootstrap-vue";
import PolygonImg from "@/assets/images/polygon.svg";
import VueSlider from "vue-slider-component";
import ColorInput from "@/views/DynamicComponents/ColorInput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { getDistance, getAreaOfPolygon } from "geolib";
import UtilityService from "@/libs/api/utility-service";
export default {
  components: {
    BForm,
    BFormGroup,
    BFormCheckbox,
    BAvatar,
    PolygonImg,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BFormTextarea,
    VueSlider,
    ColorInput,
    ValidationProvider,
    ValidationObserver,
    TagMultiSelect,
    ToastificationContent
  },
  data() {
    return {
      maxHeight: 0,
      color: "" || "#00008B",
      isEditable: true,
      tempGeofence: {},
      showLoading: false,
      layer: {},
      geoFence: {
        name: "",
        labels: [],
        description: "",
        color: "" || "#00008B",
        allow_child: true,
        allow_address_resolution: false,
        visibility_start: 0,
        visibility_end: 19,
        area: 0,
        perimeter: 0,
        boundary: {},
        visibility: [0, 19]
      }
    };
  },
  props: ["geofenceControl"],
  mounted() {
    this.setMaxHeight();
    if (this.$route.name === "geofence-view") {
      this.isEditable = false;
    }
    window.addEventListener("resize", this.setMaxHeight);
    this.tempGeofence = JSON.parse(JSON.stringify(this.geoFence));
    if (
      ["geofence-view", "geofence-edit"].includes(this.$route.name) &&
      this.$route.params.id
    ) {
      this.getGeofenceDetails(this.$route.params.id);
    }
    if (
      ["geofence-create"].includes(this.$route.name) &&
      this.$route.query.id
    ) {
      this.getGeofenceDetails(this.$route.query.id);
    }

    setTimeout(() => {
      this.geofenceControl.events.on("drawGeoFence", (data) => {
        // data.layer.setStyle({ color: "red" });
        this.layer = data.layer;
        // console.log(data);
        if (data.fenceTypes) {
          data.type = data.fenceTypes;
        }
        this.setShapeLatLang(data);
      });

      this.geofenceControl.events.on("removedGeoFence", (data) => {
        this.geoFence.boundary = {};
        this.geoFence.area = 0;
        this.geoFence.perimeter = 0;
      });
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setMaxHeight);
    this.geofenceControl.events.off("removedGeoFence");
    this.geofenceControl.events.off("drawGeoFence");
    this.geofenceControl.events.off("routeOptimized");
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  methods: {
    redirectBackToList() {
      this.$router.push({ name: "geofences-list" });
    },

    calculateShapeProperties(data) {
      const shapeType = data.type;
      const coordinates = data.coordinates;
      const radius = data.radius;
      let perimeter = 0;
      let area = 0;

      if (shapeType === "rectangle" || shapeType === "polygon") {
        // Calculate perimeter for rectangle or polygon
        for (let i = 0; i < coordinates.length; i++) {
          const nextIndex = (i + 1) % coordinates.length;
          perimeter += getDistance(coordinates[i], coordinates[nextIndex]);
        }

        // Calculate area for rectangle or polygon
        area = getAreaOfPolygon(coordinates);
      } else if (shapeType === "circle") {
        // Calculate perimeter for circle (circumference = 2 * π * radius)
        perimeter = 2 * Math.PI * radius;

        // Calculate area for circle (area = π * radius^2)
        area = Math.PI * Math.pow(radius, 2);
      } else {
        throw new Error("Unsupported shape type");
      }

      this.geoFence.perimeter = perimeter;
      this.geoFence.area = area;
      if (area / 10000 > 2) {
        if (this.geofenceControl) {
          this.geofenceControl.removeAllLayers();
          this.geoFence.boundary = {};
          this.geoFence.area = 0;
          this.geoFence.perimeter = 0;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "",
              text: this.$t("geofence.shareAreaLimit"),
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },

    clear() {
      if (this.geofenceControl) {
        this.geofenceControl.removeAllLayers();
      }
      this.geoFence = JSON.parse(JSON.stringify(this.tempGeofence));
      if (
        this.geoFence &&
        this.geoFence.boundary &&
        this.geoFence.boundary.type
      ) {
        const layer = this.geofenceControl.addLayer({
          type: this.geoFence.boundary.type
            ? this.geoFence.boundary.type.toLowerCase()
            : this.geoFence.boundary.type,
          coordinates: this.geoFence.boundary.coordinates,
          id: this.geoFence.id,
          // visibility_start: this.geoFence.visibility_start,
          // visibility_end: this.geoFence.visibility_end,
          // color: this.geoFence.color,
          radius: this.geoFence.boundary.radius
        });
        this.layer = layer;
      }
    },
    setMaxHeight() {
      const windowHeight = window.innerHeight;
      const offset = 280; // Example offset value
      this.maxHeight = windowHeight - offset;
      this.$refs.dynamicHeightDiv.style.height = `${this.maxHeight}px`;
    },
    changeColor(color) {
      // if (this.layer && this.layer.options) {
      //   this.geofenceControl._data.forEach((layer) => {
      //     if (this.layer === layer) {
      //       layer.options.color = this.geoFence.color;
      //     }
      //     return layer;
      //   });
      //   this.layer.setStyle({ color: this.geoFence.color });
      // }
    },
    cancel() {
      this.$router.push({ name: "geofences-list" });
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    setShapeLatLang(data) {
      let coordinateArray = [];
      data.type = ["rectangle", "polygon", "circle"].includes(data.type)
        ? data.type
        : data.fenceType;
      if (data.type === "rectangle" || data.type === "polygon") {
        coordinateArray = data.latlngs[0].map((coord) => {
          if (coord.lat && coord.lng) {
            return [coord.lat, coord.lng];
          } else {
            return coord;
          }
        });
      } else if (data.type === "circle") {
        coordinateArray = {
          lat: data.latlngs.lat,
          lng: data.latlngs.lng
        };
      }

      const geoCoordinates = {
        type: data.type,
        coordinates: coordinateArray,
        radius: data.radius
      };
      this.geoFence.boundary = geoCoordinates;
      this.geoFence.shape = data.type;

      // this.changeColor();
      // this.visibilityChange([
      //   this.geoFence.visibility_start,
      //   this.geoFence.visibility_end
      // ]);
      this.calculateShapeProperties(geoCoordinates);
      // if (data.type === "rectangle") {
      //   this.geoFence.area = this.calculateRectangleArea(coordinateArray);
      //   this.geoFence.perimeter =
      //     this.calculateRectanglePerimeter(coordinateArray);
      // } else if (data.type === "polygon") {
      //   this.geoFence.area = this.calculatePolygonArea(coordinateArray);
      //   this.geoFence.perimeter =
      //     this.calculatePolygonPerimeter(coordinateArray);
      // } else if (data.type === "circle") {
      //   this.geoFence.area = this.calculateCircleArea(geoCoordinates.radius); //
      //   this.geoFence.perimeter = this.calculateCirclePerimeter(
      //     geoCoordinates.radius
      //   );
      // } else {
      //   throw new Error("Unsupported shape type");
      // }
    },
    async saveGeoFences() {
      try {
        if (this.geoFence.visibility && this.geoFence.visibility.length) {
          this.geoFence.visibility_start = this.geoFence.visibility[0] || 0;
          this.geoFence.visibility_end = this.geoFence.visibility[1] || 19;
        }
        this.showLoading = true;
        let response;

        //  await this.validationForm();

        let message = this.$t("geofence.CreatedSuccessfully");
        if (this.$route.query.id && this.geoFence.id) {
          delete this.geoFence.id;
        }
        if (!this.$route.params.id) {
          response = await new UtilityService().createGeofence({
            ...this.geoFence,
            description: this.geoFence.description || ""
          });
        } else {
          response = await new UtilityService().updateGeofence({
            ...this.geoFence,
            geofence_id: this.$route.params.id,
            description: this.geoFence.description || ""
          });
          message = this.$t("geofence.updatedSuccessfully");
        }

        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$router.push({ name: "geofences-list" });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getGeofenceDetails(id) {
      try {
        this.showLoading = true;
        let response = await new UtilityService().getGeofenceDetails({
          geofence_id: id,
          account_id: this.$route.query.account_id
        });
        this.showLoading = false;
        if (response && response.data) {
          const data = response.data;
          this.geoFence = {
            labels: data.labels,
            id: data.id,
            name: this.$route.query.id ? data.name + " copy" : data.name,
            description: data.description,
            color: data.color || "#F8F8F8",
            allow_child: data.allow_child || false,
            allow_address_resolution: data.allow_address_resolution || false,
            visibility_start: data.visibility_start || 0,
            visibility_end: data.visibility_end || 19,
            area: data.area || 0,
            perimeter: data.perimeter || 0,
            boundary: data.boundary || {},
            shape:
              data.boundary && data.boundary.type ? data.boundary.type : "",
            visibility: [data.visibility_start, data.visibility_end]
          };
          if (
            (this.$route.name !== "geofence-view" && data.is_editable) ||
            (this.$route.name === "geofence-create" && this.$route.query.id)
          ) {
            this.isEditable = true;
          } else {
            this.isEditable = false;
          }
          const layer = this.geofenceControl.addLayer({
            type: data.boundary.type
              ? data.boundary.type.toLowerCase()
              : data.boundary.type,
            coordinates: data.boundary.coordinates,
            id: data.id,
            // visibility_start: data.visibility_start,
            // visibility_end: data.visibility_end,
            color: data.color,
            radius: data.boundary.radius
          });
          this.layer = layer;
          this.tempGeofence = JSON.parse(JSON.stringify(this.geoFence));
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    validateCoordinate() {
      const { type, coordinates, radius } = this.geoFence.boundary;
      if (type && Array.isArray(coordinates) && coordinates.length) {
        return true;
      }
      if (type === "circle") {
        const { lat, lng } = coordinates || {};
        return !!(radius && lat && lng);
      }
      return false;
    },

    formattedPerimeter(perimeterInMeters) {
      if (perimeterInMeters) {
        const kilometers = Math.floor(perimeterInMeters / 1000);
        const remainingMeters = (perimeterInMeters % 1000).toFixed(2);
        return `${kilometers} km ${remainingMeters} m`;
      }
      return `${0} km ${0} m`;
    },
    visibilityChange(visibility) {
      // if (this.layer && this.layer.options) {
      //   this.geofenceControl._data.forEach((layer) => {
      //     if (this.layer === layer) {
      //       layer.options.visibility_start = visibility[0];
      //       layer.options.visibility_end = visibility[1];
      //     }
      //     return layer;
      //   });
      // this.layer.options.visibility_start = visibility[0];
      // this.layer.options.visibility_end = visibility[1];
      //  }
    }
  }
};
</script>
<style lang="scss" scoped>
.geo-outer {
}
.geo-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  .btn-icon {
    width: 90px;
    padding: 0 10px;
  }
}
.geo-title {
  // color: #16161D;
  font-size: 15px;
  font-weight: 500;
}
.textarea-outer {
  margin-bottom: 1rem;
}
.area-input {
  border-radius: 4px;
  background: rgba(115, 103, 240, 0.18);
  height: 38px;
  line-height: 38px;
  padding: 0 15px;
  margin-bottom: 15px;
}
.in-label {
  // color: #515151;
  font-size: 12px;
  font-weight: 500;
}
.in-text {
  // color: #515151;
  font-size: 12px;
  font-weight: 400;
}
.pagination-outer-1 {
  display: flex;
  justify-content: flex-end;
  padding: 10px 8px 0 8px;
  .sub-icon {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.ml-0-8 {
  margin-left: 4px;
}
</style>
<style lang="scss">
.color-pick-out {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: transparent !important;
  }
}
</style>
